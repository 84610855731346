import { lazy } from "react";
import { Route, Routes } from 'react-router-dom';

const BoudoirShootEllenPage = lazy(() => import("./boudoir-shoot-ellen"));
const ContactPage = lazy(() => import("./contact"));
const OffertePage = lazy(() => import("./offerte"));
const PortfolioPage = lazy(() => import("./portfolio"));
const TarievenPage = lazy(() => import("./tarieven"));
const UwFotograaf = lazy(() => import("./uw-fotograaf"));
const Faq = lazy(() => import("./faq"));
const MiniBoudoirPage = lazy(() => import("./mini-boudoir"));
const BoudoirShootPage = lazy(() => import("./boudoir-shoot"));
const HuwelijksBoudoirPage = lazy(() => import("./huwelijksboudoir"));
const KoppelBoudoirPage = lazy(() => import("./koppel-boudoir"));

export const Routing = () => {
    return (
        <div className={window.location.pathname === "/" ? "global-home-page" : "global-page"}>
        <Routes>
            <Route path="/" element={<BoudoirShootEllenPage/>} />
            <Route path="/image/:id" element={<BoudoirShootEllenPage/>} />
            <Route path="/contact"   element={<ContactPage/>} />
            <Route path="/offerte"   element={<OffertePage/>} />
            <Route path="/portfolio" element={<PortfolioPage/>} />
            <Route path="/investering"  element={<TarievenPage/>}/>
            <Route path="/uw-fotograaf" element={<UwFotograaf/>} />
            <Route path="/faq"          element={<Faq/>} />    
            <Route path="/mini-boudoir" element={<MiniBoudoirPage/>} />
            <Route path="/boudoir-shoot" element={<BoudoirShootPage/>} />  
            <Route path="/huwelijksboudoir" element={<HuwelijksBoudoirPage/>} />
            <Route path="/koppel-boudoir" element={<KoppelBoudoirPage/>} />               
            <Route path="*" element={<BoudoirShootEllenPage/>} />
        </Routes>
        </div>
    );
};
