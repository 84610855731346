import { Suspense } from "react";
import { CircularProgress } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
export const withRouter = (component: () => React.ReactNode) => () => (
    <Router>
        <Suspense fallback={<CircularProgress size="50" />}>
            {component()}
        </Suspense>
    </Router>
);
