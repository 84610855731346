import React  from 'react';
import { createRoot } from 'react-dom/client';
import App from 'app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
