// store.ts
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the interface for the state
interface LanguageState {
  selectedLanguage: string;
}

// Define the interface for the state
interface SubMenuBoudoirShootState {
  subMenuBoudoirShootCurrentState: boolean;
}

interface SelectedMenuitemState {
  selectedMenuItem: string;
}


const storedLanguage = localStorage.getItem('selectedLanguage') || 'NL';
const storedSubMenuBoudoirShootState = (localStorage.getItem('subMenuBoudoirShootCurrentState') === 'true');
const storedSelectedMenuItem = localStorage.getItem('selectedMenuItem');

const initialLanguageState: LanguageState = {
  selectedLanguage: storedLanguage || 'NL', // Use stored value or default
};

const initialSubMenuBoudoirShootState: SubMenuBoudoirShootState = {
  subMenuBoudoirShootCurrentState: storedSubMenuBoudoirShootState, // Use stored value or default
};

const initialSelectedMenuitem: SelectedMenuitemState = {
  selectedMenuItem: storedSelectedMenuItem || '', // Use stored value or default
};

// Create a slice for language
const languageSlice = createSlice({
  name: 'language',
  initialState: initialLanguageState,
  reducers: {
    setSelectedLanguage(state, action: PayloadAction<string>) {
      state.selectedLanguage = action.payload;
      localStorage.setItem('selectedLanguage', action.payload); // Update local storage
    },
  },
});

// Create a slice for language
const subMenuBoudoirShootSlice = createSlice({
  name: 'subMenuBoudoirShootState',
  initialState: initialSubMenuBoudoirShootState,
  reducers: {
    setSubMenuBoudoirShootState(state, action: PayloadAction<boolean>) {
      state.subMenuBoudoirShootCurrentState = action.payload;
      localStorage.setItem('subMenuBoudoirShootCurrentState', String(action.payload)); // Update local storage
    },
  },
});

// Create a slice for language
const selectedMenuItemSlice = createSlice({
  name: 'selectedMenuItem',
  initialState: initialSelectedMenuitem,
  reducers: {
    setSelectedMenuItemState(state, action: PayloadAction<string>) {
      state.selectedMenuItem = action.payload;
      localStorage.setItem('selectedMenuItem', String(action.payload)); // Update local storage
    },
  },
});

// Extract action creators from the slice
export const { setSelectedLanguage } = languageSlice.actions;
export const { setSubMenuBoudoirShootState } = subMenuBoudoirShootSlice.actions;
export const { setSelectedMenuItemState } = selectedMenuItemSlice.actions;

// Configure the store with the language reducer
const store = configureStore({
  reducer: {
    language: languageSlice.reducer,
    subMenuBoudoirShootState: subMenuBoudoirShootSlice.reducer,
    selectedMenuItem: selectedMenuItemSlice.reducer,
  },
});

// Export the configured store
export default store;

// Export RootState type
export type RootState = ReturnType<typeof store.getState>;