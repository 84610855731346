import { Routing } from "pages";
import { withProviders } from "./providers";
import { useEffect } from 'react';
import './index.scss';
import { GTMHeadScript, GTMBodyScript } from 'gtm';

const App = () => {


  let previousWidth = window.innerWidth;
  const threshold = 1365; // Set your threshold for menu bar here
  const thresholdHP = 1826; // Set your threshold for header of portrait image here
  const thresholdRates = 400; // Set your threshold for rates
  
  window.addEventListener("resize", function(){
      const newWidth = window.innerWidth;
      
      if ((newWidth > threshold && previousWidth <= threshold) || (newWidth <= threshold && previousWidth > threshold)) {
          window.location.reload();
      }else if((newWidth > thresholdHP && previousWidth <= thresholdHP) || (newWidth <= thresholdHP && previousWidth > thresholdHP)) {
        window.location.reload();
      }else if((newWidth > thresholdRates && previousWidth <= thresholdRates) || (newWidth <= thresholdRates && previousWidth > thresholdRates)) {
        window.location.reload();
      }
      
      previousWidth = newWidth;
  });

  useEffect(() => {
    const handleContextmenu = (e: MouseEvent) => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])

  return (
  <>
  <GTMHeadScript />
    <div className="app">
      <Routing />
      <GTMBodyScript/>
    </div>
  </>
  );
}

export default withProviders(App);
